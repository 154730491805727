import { debounce } from "ts-debounce";

import { getDeviceInfo } from "../device-detect";
import DisplayContainer from "../display-container";
import query from "../dom-helpers/query";
import { hideInstantImage } from "../instant-image";
import { fixIEObjectFitForElement } from "../object-fit";
import scrollwatch, { TriggerPoints, isVisible } from "../scrollwatch";
import once from "../utils/dom-event-once";
import { getVideoSourceForOrientation } from "../videoplayer/util";
import "./background-videos.scss";

interface IVideoSourceOptions {
  canControl: boolean;
}

export function showWhenPlaying(video: HTMLVideoElement): void {
  const showVideo = (): void => {
    video.style.opacity = "1";
  };
  const events = ["loadeddata", "playing", "canplaythrough"];
  events.forEach(event => once(video, event, showVideo));
}

function isPlaying(video: HTMLVideoElement): boolean {
  return video.currentTime > 0 && !video.paused && !video.ended && video.readyState > 2;
}

function setVideoSource(
  video: HTMLVideoElement,
  options: IVideoSourceOptions = { canControl: true },
  fallbackImage: HTMLElement
): void {
  const isPortrait = DisplayContainer.getHeight() > DisplayContainer.getWidth();
  const wasPlaying = isPlaying(video);
  const [src] = getVideoSourceForOrientation(video, isPortrait ? "portrait" : "landscape");
  if (src && video.src !== src) {
    video.src = src;
    video.load();
    if (options.canControl && wasPlaying) {
      playVideo(video, fallbackImage);
    }
  }
}

export default function initBackgroundVideos(): void {
  const { isSafari } = getDeviceInfo();

  const backgroundVideos = query("[data-background-video]");

  backgroundVideos.forEach(backgroundVideo => {
    const video = backgroundVideo.querySelector("[data-video]") as HTMLVideoElement;
    const fallbackImage = backgroundVideo.querySelector("img");

    if (!video) return;

    // Safari has a problem with fixed background videos. It thinks they are playing
    // when they aren't so we load them as not fixed and then fix them after they can play.

    if (isSafari && video.className.includes("FullSize__fixedChild")) {
      video.addEventListener("canplay", () => {
        video.className = video.className.replace("FullSize__fixedChild", "FullSize__fixedChild--Safari");
      });
    }

    const autoPlay = video.autoplay;
    video.muted = true;
    video.defaultMuted = true;
    showWhenPlaying(video);

    if (video.readyState >= 2) {
      /* This generally shouldn't happen, but handle it just in case */
      hideInstantImage(backgroundVideo);
    } else {
      video.addEventListener("loadeddata", () => hideInstantImage(backgroundVideo));
      video.addEventListener("error", () => hideInstantImage(backgroundVideo));
    }

    if (autoPlay) {
      const onTrigger = (entry: IntersectionObserverEntry): void => {
        fixIEObjectFitForElement(video);

        if (isVisible(entry)) {
          setVideoSource(video, { canControl: false }, fallbackImage);
          playVideo(video, fallbackImage);
        } else {
          video.pause();
        }
      };

      scrollwatch(backgroundVideo, onTrigger, {
        triggerPoint: TriggerPoints.On,
      });

      // Force any videos near the top to play initially
      const { top } = backgroundVideo.getBoundingClientRect();
      if (top < DisplayContainer.getHeight() * 2) {
        playVideo(video, fallbackImage);
        // Check on our video and see if it has actually started
        setTimeout(() => {
          if (video.readyState < 2 && video.paused) {
            video.load();
            playVideo(video, fallbackImage);
          }
        }, 1000);
      }
    } else {
      setVideoSource(video, { canControl: false }, fallbackImage);
    }
  });

  const videoElements = query("[data-background-video] [data-video]");
  const refresh = debounce(
    (_event: UIEvent) =>
      videoElements.forEach((video: HTMLVideoElement) => {
        setVideoSource(video, { canControl: true }, video.parentElement?.querySelector("img"));
      }),
    100
  );
  ["resize", "orientationchange"].forEach(event => {
    window.addEventListener(event, refresh, { passive: true });
  });
}

/**
 * Play a video.
 * @param video
 */
function playVideo(video: HTMLVideoElement, fallbackImage: HTMLElement): void {
  try {
    // NOTE: Safari in iOS 13 sometimes registers an apparently paused video as playing so let's force it to be paused first
    video.pause();
    //Fix for Safari displaying browser play button over paused video in autoplay. Hide video and display fallback image if paused

    video.style.visibility = "hidden";
    fallbackImage.style.visibility = "visible";
  } catch (err) {
    // Intentionally do nothing with the error, just have to catch it in case of the iOS Safari issue mentioned above.
  }

  const play = video.play();

  if (typeof play !== "undefined") {
    // Play might get interrupted
    video.style.visibility = "visible"; // Show the video
    fallbackImage.style.visibility = "hidden";
    play
      .then(() => {
        video.style.visibility = "visible";
        fallbackImage.style.visibility = "hidden";
      })
      .catch(() => {
        // Try again?

        video.play().catch(err => {
          // If this one fails then just pause for now
          video.pause();
          video.style.visibility = "hidden";
          fallbackImage.style.visibility = "visible";
        });
      });
  }
}

import { getDeviceInfo } from "../device-detect";
import queryAll, { query } from "../dom-helpers/query";
import observe, { TriggerPoints, isVisible, unobserve } from "../scrollwatch/index";
import "./lazyload.scss";

export interface Lazyloadable {
  /** Actual picture element to load */
  pictures: HTMLPictureElement[];
  /** Container element to toggle isLoading|isLoaded class on */
  container: HTMLElement;
  /** Element to watch with Scrollwatch to trigger picture loading */
  trigger: HTMLElement;
}

export default function initLazyload() {
  const lazyLoadables = queryAll("[data-lazyload-container][data-lazyload-trigger]") as HTMLElement[];

  lazyLoadables
    .map(container => {
      return {
        pictures: queryAll("[data-lazyload-item] picture", container) as HTMLPictureElement[],
        container,
        trigger: container,
      } as Lazyloadable;
    })
    .forEach(initLazyloadable);
}

//prevent lazyloading images from showing up in print preview

function loadImagesForPrint(pictures: HTMLPictureElement[], container: HTMLElement): void {
  pictures.forEach(picture => loadPicture(picture, () => hideLoadingState(container)));
}

export function initLazyloadable({ pictures, container, trigger }: Lazyloadable) {
  let isDestroyed = false;
  const destroy = () => {
    if (!isDestroyed) {
      unobserve(container);
      isDestroyed = true;
    }
  };
  const onVisible = (entry: IntersectionObserverEntry) => {
    if (!isVisible(entry)) return;
    pictures.forEach(picture => loadPicture(picture, () => hideLoadingState(container)));
    destroy();
  };

  const handleBeforePrint = (): void => {
    loadImagesForPrint(pictures, container);
  };
  window.addEventListener("beforeprint", handleBeforePrint);

  observe(trigger, onVisible, {
    triggerPoint: TriggerPoints.Lazyload,
  });
  return destroy;
}

export function loadPicture(picture: HTMLElement, onDone: () => void) {
  const img = query("img", picture) as HTMLElement;
  const sources = queryAll("source", picture) as HTMLElement[];

  if (!img) return;

  img.addEventListener("load", onDone);

  sources.forEach(replaceDataAttributes);
  replaceDataAttributes(img);
}

export function replaceDataAttributes(element: HTMLElement) {
  const dataSrc = element.getAttribute("data-src");
  if (dataSrc) {
    element.removeAttribute(dataSrc);
    element.setAttribute("src", dataSrc);
  }

  let dataSrcset = element.getAttribute("data-srcset");
  if (dataSrcset) {
    const limitMobileDpi = element.hasAttribute("data-limit-mobile-dpi");
    if (limitMobileDpi) {
      element.removeAttribute("data-limit-mobile-dpi");
    }

    const media = element.getAttribute("media");
    let maxWidth;
    if (media) {
      if (media.indexOf("min-width") !== -1 || media.indexOf("min-aspect-ratio") !== -1) {
        /* Landscape element */
        maxWidth = window.innerWidth > window.innerHeight ? window.innerWidth : window.innerHeight;
      } else if (media.indexOf("max-width") !== -1 || media.indexOf("max-aspect-ratio") !== -1) {
        /* Portrait element */
        maxWidth = window.innerWidth < window.innerHeight ? window.innerWidth : window.innerHeight;
      }
    }

    if (limitMobileDpi && maxWidth && getDeviceInfo().isMobile) {
      let lastWidth = 0;
      dataSrcset = dataSrcset
        .split(",")
        .filter(src => {
          const value = src.trim().match(/[0-9]+w$/);
          if (value) {
            const intValue = parseInt(value[0]);
            if (intValue > maxWidth) {
              if (lastWidth) {
                return false;
              } else {
                lastWidth = intValue;
              }
            }
            return true;
          }
        })
        .join(",");
    }

    element.removeAttribute(dataSrcset);
    element.setAttribute("srcset", dataSrcset);
  }
}

export function hideLoadingState(container: HTMLElement) {
  container.classList.remove("Lazyload--isLoading");
  container.classList.add("Lazyload--isLoaded");
}
